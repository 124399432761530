import { memo } from 'react'
import type { FC } from 'react'
import type { NextPage } from 'next'
import { useTranslator } from '@/hooks'
import styled from 'styled-components'
import { Layout } from '../components'
import { vw, mq} from '@dy/commons/styles'
// import { HeadTag } from '@/components'

export interface ErrorProps {
  error?: any,
}

const Main = styled.main`
  display: flex;
  flex-direction: row;
  height: 100dvh;
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
`

const Section = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;
  padding: ${vw(15, 'mobile')} ${vw(17, 'mobile')};
  width: 80%;

  ${mq.greaterThan('tablet')`
    padding:${vw(25, 'desktop')} ${vw(50, 'desktop')};
    width: 40%;
  `}

  ${mq.greaterThan('desktop')`
    padding: 25px 50px;
  `}

  h2 {
    text-align: left;
    margin-bottom: ${vw(25, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom: ${vw(25, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      margin-bottom: 25px;
    `}
  }
`

export async function getStaticProps() {
  // const [layout, layoutError] = await fetchAPI('layout')
  // if(layoutError) return { notFound: true }

  return {
    props: {
      layout: {}
    }
  }
}

const NotFoundPage: NextPage<ErrorProps> & { Layout?:FC } = memo(() => {
  const { t } = useTranslator()

  return (
    <>
      <Main>
        <Section>
          <h2>{t('commons.page_not_found.title')}</h2>
          <p>{t('commons.page_not_found.description')}</p>
        </Section>
      </Main>
    </>
  )
})

NotFoundPage.Layout = Layout
export default NotFoundPage
